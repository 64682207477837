export const question = 
{

    q2:[
    "Große körperliche Leistung?",
    "Erhöhter Alkoholkonsum?",
    "Zigarettenkonsum bis 40 Stk. pro Tag?",
    "Zigarettenkonsum mehr als 40 Stk. pro Tag?",
    "Erhöhter Stress?",
    ],
     q3:[
    "Fühlst du dich am Nachmittag genauso wach wie am Morgen?",
    "Kannst du aus dem Gedächtnis 10 Rufnummern von Freunden nennen?",
    "Bist du frei von Angstzuständen und depressiven Stimmungen?",
    "Hast du gern anstrengende oder strittige Diskussionen?",
    "Schläfst du nachts lange und ohne Unterbrechungen durch?",
    "Verlässt du das Bett morgens mit Freude und Elan?",
    "Bist du nervenstark und belastbar in Stresssituationen?",
    "Konzentrierst du dich gern beim Lösen von Problemen?",
    "Kannst du der Handlung eines Buches konzentriert folgen?",
    ],
     q4:[
    "Leidest du häufig unter Juckreiz an den Beinen?",
    "Spürst du manchmal nach einem langen Arbeitstag die Beine?",
    "Hast du nach eigener Einschätzung Übergewicht?",
    "Hast du häufiger entzündete oder gerötete Augen?",
    "Leidest du öfter unter unerklärlicher Müdigkeit?",
    "Hast du gelegentlich erhöhte Körpertemperatur?",
    "Ist dein Gewebe morgens fester und straffer als abends?",
    "Leidest du gelegentlich unter Krämpfen in den Beinen?",
    ],   
 
    q5:[
    "Hast du manchmal Durchfall durch Stress?",
    "Hast du manchmal Magenbeschwerden nach Kaffeegenuss?",
    "Frühstückst du morgens regelmäßig?",
    "Hast du Darmgeräusche oder Blähungen nach dem Essen?",
    "Hast du Durchfall und Verstopfung abwechselnd?",
    "Ist dein Stuhl übelriechend?",
    "Trinkst du viel zum Essen?",
    "Leidest du unter Hautausschlag?",
    "Leidest du gelegentlich unter Mundgeruch?",
    ],
    
    
    q6:[
    
    "Bist du häufig abnormal oder ungewöhnlich müde?",
    "Siehst du manchmal schlecht und unscharf?",
    "Hast du weiße Flecken unter den Fingernägeln?",
    "Hast du Altersflecken?",
    "Hast du häufig entzündete Schleimhäute?",
    "Bist du häufig erkältet?",
    "Leidest du öfter unter Entzündungen? (z.B. Mandel oder Blasenentzündung)",
    "Reagierst du auf einige Lebensmittel allergisch?",
    "Leidest du unter Haarausfall?",
    "Ist deine Haut manchmal trocken?",
    "Reagierst du auf einige Lebensmittel allergisch?",
    "Hast du manchmal Hautausschlag?",
    "Heilen bei dir kleine Wunden nur langsam?",
    ],
    
    q7:[
    
    "Hast du manchmal ein störendes Druckgefühl im Gebiß?",
    "Meidest du festes Zubeißen mit den Schneidezähnen?",
    "Kaust du Hartes nur auf einer Seite?",
    "Haben deine Zähne helle und dunkle Flecken?",
    "Ist dein Zahnfleisch druck- oder schmerzempfindlich?",
    "Leidest du unter Zahnfleischbluten?",
    "Juckt dein Zahnfleisch manchmal?",
    "Ist dein Zahnfleisch manchmal gerötet oder geschwollen?",
    ],
    
    q8:[
    
    "Bist du nach längerem Sitzen beim Aufstehen steif?",
    "Schläfst du nachts in gekrümmter Haltung?",
    "Arbeitest du am Schreib- oder Küchentisch vornübergebeugt?",
    "Leidest du häufig unter Entzündungen?",
    "Knirschen deine Nackenwirbel, wenn du den Kopf bewegst?",
    "Schläfst du auf sehr weicher Unterlage?",
    "Hast du starkes Übergewicht?",
    "Leidest du häufig unter Hexenschuß oder Ischias?",
    "Fällt es dir schwer, über längere Zeit gerade zu stehen?",
    "Ist deine Haltung morgens aufrechter als abends?",
    ],
    
    q9:[
    
    "Hast du oft blaue Flecken oder geplatzte Äderchen?",
    "Wird deine Haut ohne Feuchtigkeitscreme leicht rissig?",
    "Glänzt deine Haut an Stirn und Nase?",
    "Hast du fettiges Haar?",
    "Bekommst du leicht Sonnenbrand?",
    "Ist deine Haut an Gesicht und Hals abends welker?",
    "Hast du öfter Juckreiz an diversen Körperstellen?",
    "Hast du winzige Fältchen über der Oberlippe?",
    "Ist deine Haut an der Oberarminnenseite weicher als im Gesicht?",
    "Hast du Kopfhautjucken?",
    ],
    
    q10:[
    
    "Hast du Haarausfall?",
    "Leidest du manchmal nach Mahlzeiten unter Übelkeit?",
    "Hast du manchmal unerklärliche Hautflecken im Gesicht?",
    "Kommst du schwer von deinem Übergewicht herunter?",
    "Hast du oft Hautjucken an diversen Körperstellen?",
    "Sind deine Lippen manchmal geschwollen?",
    "Leidest du häufig unter Durchfall?",
    "Musst du häufig niesen?",
    "Leidest du manchmal unter Blasenbeschwerden?",
    "Bist du oft müde und schlapp?",
    ],
    
    q11:[
    
    "Brauchst du zum Einschlafen länger als 15 Minuten?",
    "Wechseln deine Stimmungen tagsüber?",
    "Wirst du oft hellwach, nachdem du gerade erst müde ins Bett gegangen bist?",
    "Ist die Qualität deines Schlafes wetterabhängig?",
    "Kannst du nur im Dunkeln einschlafen?",
    "Wachst du oft frühmorgens mit depressiven Stimmungen auf?",
    "Brauchst du zum Einschlafen ein Stimulans (z.B. Alkohol)?",
    "Wachst du nachts öfter als dreimal auf?",
    "Kannst du nur einschlafen, wenn du alleine bist?",
    "Hast du belastende Träume?",
    ],
    
    q12:[
    
    "Bist du häufig depressiv?",
    "Fällt es dir schwer, dich zu freuen?",
    "Beschäftigst du dich mit der Vergangenheit mehr als mit der Gegenwart?",
    "Wirst du schnell aggressiv?",
    "Bist du schmerzempfindlich?",
    "Bist du überrascht darüber, wie unbeherrscht du manchmal reagierst?",
    "Greifst du tagsüber häufig zu Süßigkeiten?",
    "Bist du oft unerklärlich müde?",
    "Leidest du zeitweise an Blasenschwäche oder Harnverhaltung?",
    "Fällt es dir schwer, ruhig zu sitzen?",
    ],
    

    
    }

