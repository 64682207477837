import React, { useState } from "react"
import { useForm } from "react-hook-form"
import Layout from "../components/layout"
import styles from "../styles/analyse.module.css"
import Sliderblock from "../components/Form/sliderblock"
import Checkboxblock from "../components/Form/checkboxblock"
import Forminputtext from "../components/Form/forminputtext"
import Forminputcheck from "../components/Form/forminputcheck"
import Privacycheck from "../components/Form/privacycheck"
import { question } from "../components/Form/questions"

const Analysis = () => {
  const [success, setSuccess] = useState("empty")
  const [inputvalue, setInputvalue] = useState("")
  const { register, handleSubmit } = useForm()
  const [privacy, setPrivacy] = useState(false)
  const [privacyAlert, setPrivacyAlert] = useState(false)

  const onSubmit = data => {
    fetch("https://formspree.io/f/mgepgbap", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then(data => {
      if (data.status === 200) {
        setSuccess("success")
      } else {
        setSuccess("failed")
      }
    })
  }

  const onChange = event => {
    setInputvalue(event.target.value)
  }
  const checklogin = event => {
    if (inputvalue === "nobusan") {
      setSuccess("passed")
    }
  }

  const onkeyPress = event => {
    if (event.key === "Enter") {
      checklogin()
    }
  }

  const privacyHandler = event => {
    setPrivacy(!privacy)
  }

  const privacyCheck = () => {
    if (!privacy) {
      setPrivacyAlert(true)
    } else {
      setPrivacyAlert(false)
    }
  }

  if (success === "empty") {
    return (
      <Layout>
        <h1>Vitalstoffanalyse</h1>
        <p>
          Du hast von mir ein Passwort erhalten damit du diese Seite benutzen
          kannst.<br></br> Bitte trag es hier ein:
        </p>
        <input
          type="password"
          placeholder="Passwort eingeben!"
          className={styles.passwordinput}
          onKeyPress={onkeyPress}
          onChange={onChange}
        />
        <button onClick={checklogin} className={styles.submit}>
          los gehts!
        </button>
      </Layout>
    )
  } else if (success === "passed") {
    return (
      <Layout>
        <h1>Vitalstoffanalyse</h1>
        <h2>Prävention ist die Gesundheit der Zukunft!</h2>
        <p>
          Um dich als Vitality-Coach bestmöglich unterstützen zu können, ist es
          wichtig einen IST-Status deiner gesundheitlichen Situation zu erheben.
          In dieser ersten Analyse werden unterschiedliche Bereiche abgefragt
          und daraus ermittle ich deine persönliche Auswertung.
          <strong>
            Gemeinsam entwickeln wir dann einen passenden Fahrplan für dich um
            deine Ziele zu erreichen.
          </strong>
        </p>
        <h3>Und so funktioniert's:</h3>
        <p>
          Für die <strong>Vitalstoffanalyse</strong> kannst du untenstehendes
          Formular ausfüllen, welches mit dem "JETZT ABSENDEN" Button an mich
          übermittelt wird. Sobald ich die Auswertung durchgeführt habe (Dauer
          2-3 Werktage) erhälst du von mir eine E-Mail mit den Ergebnissen und
          Empfehlungen.
        </p>

        <h3>bist du bereit? Dann lass uns loslegen!</h3>

        <p>
          Oder{" "}
          <a href="/VITALSTOFFANALYSE.pdf" download>
            hier PDF downloaden
          </a>
          , audrucken, ausfüllen und an mich per E-Mail retournieren.
        </p>
        <div className={styles.mainFormWrapper}>
          <form method="POST" onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.formWrapper}>
              <h3 className={styles.question}>Frage 1:</h3>
              <p className={styles.text}>
                Zuerst möchte ich deine persönliche Einschätzung erfahren.
                <br />
                Bewerte ganz spontan deine Empfindungssituation in folgenden
                Bereichen. <br></br>(0 - sehr schlecht / 10 - sehr gut)
              </p>

              <Sliderblock register={register} text="Konzentration" />
              <Sliderblock register={register} text="Verdauung" />
              <Sliderblock register={register} text="Allergie" />
              <Sliderblock register={register} text="Knochen/Skelett" />
              <Sliderblock register={register} text="Haut" />
              <Sliderblock register={register} text="Schlafqualität" />
              <Sliderblock register={register} text="Nervenkostüm" />
              <Sliderblock register={register} text="Energie" />
              <Sliderblock register={register} text="Vitalität" />
              <Sliderblock
                register={register}
                text="Gesundheit/Gesamteinschätzung"
              />
            </div>

            <Checkboxblock
              questions={question.q2}
              register={register}
              h3="Frage 2"
              p="Angaben zu deiner persönlichen Lebenssituation."
            />

            <Checkboxblock
              questions={question.q3}
              register={register}
              h3="Frage 3"
              p="Deine Angaben zu Gedächtnisschwäche und Konzentrationsmangel"
            />

            <Checkboxblock
              questions={question.q4}
              register={register}
              h3="Frage 4"
              p="Deine Angaben zu Krampfadern und Hämorrhoiden"
            />

            <Checkboxblock
              questions={question.q5}
              register={register}
              h3="Frage 5"
              p="Deine Angaben zu Durchfall, Blähungen und Verstopfung"
            />

            <Checkboxblock
              questions={question.q6}
              register={register}
              h3="Frage 6"
              p="Deine Angaben zu Entzündungen und Erkältungen"
            />

            <Checkboxblock
              questions={question.q7}
              register={register}
              h3="Frage 7"
              p="Deine Angaben zu Karies, Zahnfleischbluten und Zahnfleischschwund"
            />

            <Checkboxblock
              questions={question.q8}
              register={register}
              h3="Frage 8"
              p="Deine Angaben zu Rückenschmerzen und Bandscheibenbeschwerden"
            />

            <Checkboxblock
              questions={question.q9}
              register={register}
              h3="Frage 9"
              p="Deine Angaben zu Juckreiz, Hautausschlag und Entzündungen"
            />

            <Checkboxblock
              questions={question.q10}
              register={register}
              h3="Frage 10"
              p="Deine Angaben zu Heuschnupfen, Asthma und Entzündungen"
            />

            <Checkboxblock
              questions={question.q11}
              register={register}
              h3="Frage 11"
              p="Deine Angaben zu Ein- und Durchschlafstörungen"
            />

            <Checkboxblock
              questions={question.q12}
              register={register}
              h3="Frage 12"
              p="Deine Angaben zu Nervosität, Gereiztheit und Angst"
            />

            <div className={styles.formWrapper}>
              <Forminputtext
                text="Vorname*"
                type="text"
                required
                register={register}
              />
              <Forminputtext
                text="Nachname*"
                type="text"
                required
                register={register}
              />
              <Forminputtext
                text="eMail*"
                type="email"
                required
                register={register}
              />
              <Forminputtext text="Straße" type="text" register={register} />
              <Forminputtext text="PLZ/Ort" type="text" register={register} />
              <Forminputtext text="Telefon" type="tel" register={register} />
              <Forminputtext
                text="Alter in Jahren"
                type="number"
                register={register}
              />
              <Forminputtext
                text="Körpergröße in cm"
                type="number"
                register={register}
              />

              <Forminputtext
                text="Gewicht in kg"
                type="number"
                register={register}
              />
              <Forminputtext
                text="Bist du Vegetarier?"
                type="text"
                register={register}
              />
              <Forminputtext
                text="Ernährst du dich mit Reduktionskost?"
                type="text"
                register={register}
              />

              <div className={styles.forminputchecks}>
                <Privacycheck register={register} privacy={privacyHandler} />
              </div>
            </div>
            {privacyAlert ? <h1>Bitte bestätige den Datenschutz</h1> : null}

            <input
              onClick={privacyCheck}
              className={styles.submit}
              type="submit"
            />

            <input type="text" name="_gotcha" style={{ display: "none" }} />
          </form>
        </div>
      </Layout>
    )
  } else if (success === "success") {
    return (
      <Layout>
        <div className={styles.success}>
          <h1>
            Vielen Dank, ich habe das Formular erhalten und melde mich bei dir!
          </h1>
        </div>
      </Layout>
    )
  } else if (success === "failed") {
    return (
      <Layout>
        <h1>
          Das hat leider nicht funktioniert. Bitte versuch es nochmal oder
          kontaktiere mich direkt!
        </h1>
      </Layout>
    )
  }
}

export default Analysis
