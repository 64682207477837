import React from 'react'
import styles from "../../styles/analyse.module.css";
import Checkboxes from "./Checkboxes"

const Checkboxblock = (props) => {

    return (
        
        <div className = {styles.formWrapper}>
        <h3 className= {styles.question}>{props.h3}</h3>
        <p className = {styles.text}>{props.p}<br>
        </br>Bitte Zutreffendes auswählen.</p>
        
        <Checkboxes questions = {props.questions} register = {props.register} />
        </div>
    )
}

export default Checkboxblock
