import React from 'react'
import checkstyle from "../../styles/checkbox.module.css";


//styles.yesnolabel


const Checkboxes = ({questions, register}) => {
    return (
        
        <div>

       
        
        {questions.map((question, index) => {

            return (
                <label className={checkstyle.container}
                key = {index}>
            
                <input type="checkbox" name={question} value="JA"
                ref={register} className={checkstyle.checkbox}/>
                {question}
                <span className={checkstyle.checkmark}></span>
                
                                           
              </label>
            )
        })}

    </div>
        
    
    )
}

export default Checkboxes
