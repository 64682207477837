import React from 'react'
import styles from "../../styles/analyse.module.css";


const Sliderblock = ({text, register}) => {
    return (
        <div className={styles.slideMainWrapper}>

       
        <label>
             <span>{text}</span> 
        <div className = {styles.slideWrapper}>                          
        <span className={styles.slideNumber}>0</span><input type="range" name={text} min="0" max="10"
        className = {styles.slider}  ref={register}/>  <span className={styles.slideNumber}>10</span>   
         </div>      
    </label>
    </div>
    )
}

export default Sliderblock
